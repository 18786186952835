.profileimg {
  cursor: pointer;
}

.sidemenuicon {
  cursor: pointer;
}

.dropdownStyle {
  margin-top: -38% !important;
  // display: contents !important;
}