.sidemenu {
  left: 0 !important;
  visibility: visible;
}

.hidesidebar {
  visibility: hidden;
}

.showsidebar {
  visibility: visible;
}

.sidebar {
  border-right: 3px solid rgb(229, 234, 239) !important;
}

.dashboaricon {
  cursor: pointer;
}