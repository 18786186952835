.hoverStyle {
  cursor: pointer;
}

.errormsg {
  font-size: 12px;
  color: red;
}

.errorstar{
  color: red;
  margin-left: 4px;
}

.logintext {
  display: flex;
  justify-content: center;
}

.socialbtn {
  height: 45px !important;
  padding-top: 4px !important;
  font-size: 15px !important;
}